// TODO(rolly) this is probably missing some edge cases
export const baseUrl = (
  process.env.NEXT_PUBLIC_APP_URL
    ? process.env.NEXT_PUBLIC_APP_URL
    : `https://${process.env.VERCEL_URL}`
) as string

export const baseHost = new URL(baseUrl).host

export function makeUrl(path?: string | null): string {
  if (!path) {
    return baseUrl
  }

  return baseUrl + path
}
